// 'use client';
import React from 'react';
// import { AsyncImage } from 'loadable-image';
import Image from 'next/image';

const CardImage = ({ p }) => {
  return (
    <Image
      alt=""
      src={p?.node?.image?.sourceUrl || '/images/a4g.png'}
      // srcSet={p?.node?.image?.srcSet || ''}
      // width={400}
      // height={400}
      // className="w-full h-full"
      // fill
      // quality={75}
      // resposive={true}
      fill
      sizes="(max-width: 768px) 100vw, 33vw"
      style={{ objectFit: 'cover' }}
      className="rounded-lg"
    />

    // <AsyncImage
    //   src={p?.node?.image?.sourceUrl || '/images/a4g.png'}
    //   srcSet={p?.node?.image?.srcSet || ''}
    //   style={{ width: '100%', height: '100%', aspectRatio: 1 / 1 }}
    //   alt={p?.node?.title}
    //   loader={
    //     <div className="flex justify-center items-center bg-transparent">
    //       <div className="border-gray-600 h-10 w-10 animate-spin rounded-full border-2 border-t-gray-800" />
    //     </div>
    //   }
    // />
  );
};

export default CardImage;
