'use client';
import React, { useEffect, useState, useRef, Suspense } from 'react';
import useSWR from 'swr';
import LotteryCard from './lotteryCard';
import { getLotteriesByTypes } from '@/app/components/homepage/actions';
import AllLottery from './giveawayTabs/allLottery';

const fetcher = async () => {
  return await getLotteriesByTypes().then((res) => {
    return {
      active: res?.active || null,
      instantwins: res?.instantwins || null,
      recentended: res?.recentended || null,
    };
  });
};

const ProductList = ({ lotteries }) => {
  return (
    <>
      {lotteries?.map((p, i) => {
        return (
          <div
            key={i}
            className="bg-blur-sm  h-full  justify-center p-8 px-4 pt-0 w-1/2 sm:w-1/2 md:w-1/3 lg:px-3  xl:w-1/4 "
          >
            <LotteryCard p={p || null} />
          </div>
        );
      })}
    </>
  );
};

const GiveawaysTabsContent = ({ lotteries }) => {
  const currentPage = useRef({
    active: lotteries?.active || [],
    instantwins: lotteries?.instantwins || null,
    recentended: lotteries?.recentended || null,
  });

  const { data, error } = useSWR('lotteriesByTypes', fetcher, {
    revalidateOnMount: true,
  });

  useEffect(() => {
    if (data) {
      currentPage.current = {
        active: data?.active || [],
        instantwins: data?.instantwins || null,
        recentended: data?.recentended || null,
      };
    }
  }, [data]);

  return (
    <>
      {/* All */}
      <section
        id="sec-1"
        className="mb-20 flex min-h-[600px] flex-wrap justify-center px-2 pt-5 lg:-mx-4"
      >
        <Suspense>
          <AllLottery lotteries={lotteries?.all} />
        </Suspense>
      </section>
      {/* Active Products */}
      <section
        id="sec-2"
        className="mb-20 flex min-h-[600px] flex-wrap justify-center px-2 pt-5 lg:-mx-4"
      >
        <Suspense>
          <ProductList lotteries={currentPage?.current?.active} />
        </Suspense>
      </section>

      {/* Instant wins */}
      <section
        id="sec-3"
        className="mb-20 flex min-h-[600px] flex-wrap justify-center px-2 pt-5 lg:-mx-4"
      >
        <Suspense>
          <ProductList lotteries={currentPage?.current?.instantwins} />
        </Suspense>
      </section>

      {/* recent ended lottery */}
      <section
        id="sec-4"
        className="mb-20 flex min-h-[600px] flex-wrap justify-center px-2 pt-5 lg:-mx-4"
      >
        <Suspense>
          <ProductList lotteries={currentPage?.current?.recentended} />
        </Suspense>
      </section>
    </>
  );
};

export default GiveawaysTabsContent;
