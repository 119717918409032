'use client';
import React, { useEffect, useState, useRef } from 'react';
import useSWRInfinite from 'swr/infinite';
import LotteryCard from '../lotteryCard';
import { fetchAllLotteryPaginated } from '../actions';

const LOTTERY_CARD_FIELDS = `node {
  ... on LotteryProduct {
    name
    databaseId
    slug
    status
    title
    lotteryFromDate
    lotteryToDate
    lotteryHasStarted
    lotteryInstantWin
    lotteryMaxTickets
    lotteryParticipantsCount
    lotteryClosed
    lotteryPrice
    lotterySalePrice
    lotteryInstantWinPoints
    lotteryUsePickNumbers
    lotteryManualyWinners
    price
    regularPrice
    metaData {
      key
      value
    }
  }
  image {
    srcSet
    sourceUrl
  }
}`;

const ALL_LOTTERIES_PAGINATED = `query allLotteriesPaginated($first: Int!, $after: String) {
  products(where: { lotteriesAll: true, status: "publish" } first: $first, after: $after) {
    edges {
      ${LOTTERY_CARD_FIELDS}
    }
     found
    pageInfo {
      hasNextPage
      endCursor
      hasPreviousPage
    }
  }
}`;

const fetcher = async (params) => {
  return await fetchAllLotteryPaginated(params[0], params[1]).then((res) => {
    return {
      products: res?.products || [],
      pageInfo: res?.pageInfo || null,
    };
  });
};

const getKey = (pageIndex, previousPageData) => {
  if (!previousPageData) {
    return ALL_LOTTERIES_PAGINATED
      ? [
          ALL_LOTTERIES_PAGINATED,
          {
            first: 8,
            after: '',
            type: 'paginatedAllLotteries',
          },
        ]
      : null;
  }
  // reached the end
  if (previousPageData && !previousPageData?.pageInfo?.hasNextPage) {
    return null;
  }

  // has next page - return last cursor
  if (previousPageData && previousPageData?.pageInfo?.hasNextPage) {
    return ALL_LOTTERIES_PAGINATED
      ? [
          ALL_LOTTERIES_PAGINATED,
          {
            first: 8,
            after: previousPageData?.pageInfo?.endCursor || null,
            type: 'paginatedAllLotteries',
          },
        ]
      : null;
  }
};

const AllLottery = ({ lotteries, filter }) => {
  const preFetched = useRef({ products: null, pageInfo: null });
  const currentPage = useRef({
    products: lotteries?.products,
    pageInfo: lotteries?.pageInfo,
  });

  const { data, size, setSize, error, isValidating, isLoading } =
    useSWRInfinite(getKey, fetcher, { revalidateOnMount: true });

  useEffect(() => {
    let allData = [];
    let lastPageInfo = {};

    if (data) {
      data?.map((pageData) => {
        if (pageData?.products) {
          allData = [...allData, ...pageData?.products];
          lastPageInfo = pageData?.pageInfo;
        }
      });

      if (allData?.length > 0) {
        preFetched.current.products = allData;
        preFetched.current.pageInfo = lastPageInfo;

        if (preFetched?.current?.pageInfo?.hasPreviousPage === false) {
          currentPage.current.products = preFetched?.current?.products;
          currentPage.current.pageInfo = preFetched?.current?.pageInfo;
          if (preFetched?.current?.pageInfo?.hasNextPage) {
            setSize(size + 1);
          }
        }
      }
    }
  }, [data]);

  const loadMore = (event) => {
    event.preventDefault();
    currentPage.current.products = preFetched?.current?.products;
    currentPage.current.pageInfo = preFetched?.current?.pageInfo;

    if (preFetched?.current?.pageInfo?.hasNextPage) {
      setSize(size + 1);
    }
  };

  const products = currentPage?.current?.products || lotteries?.products || [];
  const pageInfo =
    currentPage?.current?.pageInfo || lotteries?.pageInfo || null;

  return (
    <>
      {products?.map((p, i) => {
        return (
          <div
            key={i}
            className="bg-blur-sm  h-full  justify-center p-8 px-4 pt-0 w-1/2 sm:w-1/2 md:w-1/3 lg:px-3  xl:w-1/4 "
          >
            <LotteryCard p={p || null} />
          </div>
        );
      })}

      {pageInfo?.hasNextPage && (
        <div className="flex w-full justify-center pt-10">
          <button
            className="w-auto p-2 px-5 bg-brand text-white rounded-3xl"
            onClick={(e) => loadMore(e)}
          >
            Load More
          </button>
        </div>
      )}
    </>
  );
};

export default AllLottery;
