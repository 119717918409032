// WooCommerce.get("products")
//   .then((response) => {
//     console.log(response.data);
//   })
//   .catch((error) => {
//     console.log(error.response.data);
//   });
'use server';
import { executeQry } from '@/helpers/QUERY_FETCHER';
import { woocomRESTApi } from '@/helpers/WOO_REST_API_FETCHER';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';

dayjs.locale('en-gb'); // use locale

const LOTTERY_CARD_FIELDS = `node {
  ... on LotteryProduct {
    name
    databaseId
    slug
    status
    title
    lotteryFromDate
    lotteryToDate
    lotteryHasStarted
    lotteryInstantWin
    lotteryMaxTickets
    lotteryParticipantsCount
    lotteryClosed
    lotteryPrice
    lotterySalePrice
    lotteryInstantWinPoints
    lotteryUsePickNumbers
    lotteryManualyWinners
    price
    regularPrice
    metaData {
      key
      value
    }
  }
  image {
    srcSet
    sourceUrl
  }
}`;

export const getUserNameById = async (id) => {
  const USERNAME = `query user($id: ID!) {
    user(id: $id, idType: DATABASE_ID) {
      name
    }
  }
  `;
  try {
    const { data, error } = await executeQry(USERNAME, { id: id });
    return data?.user?.name || 'Unknown';
  } catch (error) {
    console.error(`Error fetching user name for user ${id}:`, error);
    return 'Unknown';
  }
};

const ALL_LOTTERIES = `query allLotteries($first: Int!, $after: String) {
  products( where: { lotteriesAll: true, status: "publish" } first: $first, after: $after) {
    edges {
      ${LOTTERY_CARD_FIELDS}
    }
     found
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    }
  }
}`;

export const fetchAllLotteryPaginated = async (
  gQry = ALL_LOTTERIES,
  params = null,
  size = 8,
  prevData = [],
) => {
  const { data, error, loading } = await executeQry(gQry, {
    after: params?.after || '',
    first: size,
  });

  if (error) {
    return {
      products: null,
      pageInfo: null,
    };
  }

  if (data) {
    const filteredData =
      data?.products?.edges?.filter((x) => x?.node?.status == 'publish') || [];

    const pageData = [...prevData, ...filteredData];

    return {
      products: [...pageData],
      pageInfo: data?.products?.pageInfo || null,
    };
  }
};

export const fetchLotteries = async (
  params = null,
  size = 99,
  prevData = [],
) => {
  const { data, error, loading } = await executeQry(ALL_LOTTERIES, {
    after: params?.after || '',
    first: params?.first || size,
  });

  if (error) {
    return {
      products: null,
      pageInfo: null,
    };
  }

  if (data) {
    const filteredData =
      data?.products?.edges?.filter((x) => x?.node?.status == 'publish') || [];

    const pageData = [...prevData, ...filteredData];
    const sortage = size > pageData?.length ? size - pageData?.length : 0;

    if (sortage > 0 && data?.products?.pageInfo?.hasNextPage) {
      return fetchLotteries(
        {
          after: data?.products?.pageInfo?.endCursor || null,
          first: sortage,
        },
        size,
        pageData,
      );
    }

    return {
      products: [...pageData],
      pageInfo: data?.products?.pageInfo || null,
    };
  }
};

export const getLotteriesByTypes = async () => {
  const { products } = await fetchLotteries(null, 99);

  const todayDate = dayjs();
  const beforeDate = dayjs().add(-60, 'days');

  const active = products?.filter(
    (x) =>
      x?.node?.status == 'publish' &&
      x?.node?.metaData?.find((l) => l.key == '_lottery_has_started')?.value ==
        '1' &&
      x?.node?.metaData?.find((l) => l.key == '_lottery_closed')?.value !==
        '2' &&
      dayjs(
        x?.node?.metaData?.find((l) => l.key == '_lottery_dates_to')?.value,
      ) >= todayDate,
  );

  const instantwins = active?.filter(
    (x) =>
      x?.node?.status == 'publish' &&
      x?.node?.metaData?.find((l) => l.key == '_lottery_instant_win')?.value ==
        'yes',
  );

  const recentended = products?.filter(
    (x) =>
      x?.node?.status == 'publish' &&
      dayjs(
        x?.node?.metaData?.find((l) => l.key == '_lottery_dates_to')?.value,
      ) >= beforeDate &&
      dayjs(
        x?.node?.metaData?.find((l) => l.key == '_lottery_dates_to')?.value,
      ) < todayDate,
  );

  return {
    active,
    instantwins,
    recentended,
  };
};

const getWinPosition = (win_position) => {
  if (win_position == '1') {
    return '1st Prize';
  }
  if (win_position == '2') {
    return '2nd Prize';
  }
  if (win_position == '3') {
    return '3rd Prize';
  }

  return `${win_position}th Prize`;
};

export const getWonTicketsPrizesByLotteryIds = async (lotteryIds = []) => {
  const PRODUCTS_META_QUERIES = `query getLotteries($include: [Int]) {
    products(where: {include: $include}, first: 100) {
      edges {
        node {
          databaseId
          name
          metaData {
            key
            value
          }
        }
      }
      found
    }
  }`;

  const { data, error, loading } = await executeQry(PRODUCTS_META_QUERIES, {
    include: lotteryIds,
  });

  const ticketPrizeList = [];

  data?.products?.edges?.length > 0 &&
    data?.products?.edges?.map((item) => {
      const instaCashWinners =
        item?.node?.metaData?.find(
          (i) => i?.key === '_lottery_instant_ticket_numbers_prizes',
        )?.value || null;

      if (instaCashWinners) {
        const instaCashWin = JSON.parse(
          instaCashWinners.replace(/[/\\*]/g, ''),
        );

        const wonTickets = [];
        instaCashWin &&
          Object.values(instaCashWin).map((p) => wonTickets.push(p));

        wonTickets?.map((t) =>
          ticketPrizeList.push({
            product_id: item?.node?.databaseId,
            prize_type: 'insta_wins',
            ticket: t.ticket,
            prize: t.prize,
          }),
        );
      }

      const instaPointsWinners =
        item?.node?.metaData?.find(
          (i) => i?.key === '_lottery_instant_ticket_numbers_prizes_points',
        )?.value || null;

      if (instaPointsWinners) {
        const pointWinerData = JSON.parse(
          instaPointsWinners.replace(/[/\\*]/g, ''),
        );
        const wonTickets = [];
        pointWinerData &&
          Object.values(pointWinerData).map((p) => wonTickets.push(p));

        wonTickets?.map((t) =>
          ticketPrizeList.push({
            product_id: item?.node?.databaseId,
            prize_type: 'insta_points_wins',
            ticket: t.ticket,
            prize: t.points_prize,
          }),
        );
      }

      const manualWinners =
        item?.node?.metaData?.find((m) =>
          m?.key?.includes('_lottery_manualy_winner_'),
        ) || [];

      if (manualWinners?.lenght > 0) {
        const wonTickets = [];
        manualWinners?.map((w) => {
          wonTickets.push({
            ticket: w?.value,
            prize: getWinPosition(w?.key?.slice(-1)),
          });
        });

        wonTickets?.map((t) =>
          ticketPrizeList.push({
            product_id: item?.node?.databaseId,
            prize_type: 'manual_wins',
            ticket: t.ticket,
            prize: t.prize,
          }),
        );
      }
    });

  // console.log('ticketPrizeList=>', ticketPrizeList);
  return { ticketPrizeList };
};

export const getRecentWinners = async () => {
  const { products } = await fetchLotteries(null, 30);

  const recentWinners = [];

  // console.log('products==>', products);

  products &&
    products?.map((item) => {
      const instaWinners = item?.node?.metaData?.find(
        (item) => item.key === '_lottery_instant_instant_winners',
      )?.value;

      if (instaWinners != undefined) {
        if (Array.isArray(JSON.parse(instaWinners))) {
          JSON.parse(instaWinners).map((wData) => {
            recentWinners.push({
              product_title: item?.node?.title,
              product_slug: item?.node?.slug,
              prize_type: 'Instant Wins',
              ...wData,
            });
          });
        } else {
          const instaWinerData = JSON.parse(instaWinners);
          recentWinners.push({
            product_title: item?.node?.title,
            product_slug: item?.node?.slug,
            prize_type: 'Instant Wins',
            ...instaWinerData,
          });
        }
      }

      const instaPointsWinners = item?.node?.metaData?.find(
        (item) => item.key === '_lottery_instant_points_winners',
      )?.value;

      if (instaPointsWinners != undefined) {
        if (Array.isArray(JSON.parse(instaPointsWinners))) {
          JSON.parse(instaPointsWinners).map((wData) => {
            recentWinners.push({
              product_title: item?.node?.title,
              product_slug: item?.node?.slug,
              prize_type: 'Instant Points Wins',
              ...wData,
            });
          });
        } else {
          const pointWinerData = JSON.parse(instaPointsWinners);
          recentWinners.push({
            product_title: item?.node?.title,
            product_slug: item?.node?.slug,
            prize_type: 'Instant Points Wins',
            ...pointWinerData,
          });
        }
      }

      const manualWinners = item?.node?.metaData?.filter((item) =>
        item.key.includes('_lottery_manualy_winner_'),
      );

      manualWinners?.map((w) => {
        if (w?.value) {
          recentWinners.push({
            win_position: getWinPosition(w?.key?.slice(-1)),
            user_id: w?.value,
            product_id: item?.node?.databaseId,
            product_slug: item?.node?.slug,
            product_title: item?.node?.title,
            prize_type: 'manual',
          });
        }
      });
    });

  const winners = [];
  recentWinners?.map(async (item) => {
    const userName = await getUserNameById(item?.user_id);
    winners.push({ user_name: userName, ...item });
  });
  return winners;
};
