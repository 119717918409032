'use client';
import React, { useEffect, useRef } from 'react';
import useSWR from 'swr';
import Image from 'next/image';
import MultiCarousel from './MultiCarousel';
import Link from 'next/link';
import { getRecentWinners } from './actions';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 5,
    partialVisibilityGutter: 40,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 4,
    partialVisibilityGutter: 30,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 25,
    slidesToSlide: 1,
  },
};

const fetcher = async () => {
  return await getRecentWinners().then((res) => {
    return res;
  });
};

const RecentWinners = ({ recentWinners }) => {
  const winners = useRef(recentWinners);

  const { data, error } = useSWR('recentwinnersdata', fetcher, {
    revalidateOnMount: true,
  });

  useEffect(() => {
    if (data) {
      winners.current = data || [];
    }
  }, [data]);

  return (
    <div className="container mx-auto ">
      <div className="my-5  flex">
        <span className="mr-2 px-4 py-1 font-bebas-neue text-[39px]  text-dark dark:text-white">
          RECENT WINNERS
        </span>
      </div>

      <div className="mx-0 pb-20 p-5  relative bg-gray-300 dark:bg-gray-900/40 rounded-lg">
        <MultiCarousel responsive={responsive}>
          {winners?.current?.map((item, i) => {
            return (
              <div
                key={i}
                className="grid justify-center py-5 gap-3 bg-gray-900/20 min-h-[500px]"
              >
                <div className="flex justify-center mt-10">
                  <div className="relative aspect-[1/1] w-32 h-32 ">
                    <Image
                      alt={item?.node?.name}
                      fill
                      src="/images/winners.png"
                      className="object-cover p-[1px] dark:p-0 rounded-full"
                    />
                  </div>
                </div>
                <label className="w-full text-center font-bold text-white text-lg relative -top-5 ">
                  {`@ ${item?.user_name}`}
                </label>
                <div className="flex justify-center relative -top-5 h-3">
                  <div className="relative aspect-[1/1] w-8 h-8 ">
                    <Image
                      alt={item?.product_title}
                      fill
                      src="/images/Award.png"
                      className="object-cover p-[1px] dark:p-0 rounded-full"
                    />
                  </div>
                </div>
                <Link
                  href={`/product/${item?.product_slug}`}
                  className="text-brand text-center w-full px-5 font-bold text-lg uppercase line-clamp-5 hover:underline hover:text-white"
                >
                  {item?.product_title}
                </Link>
                <div className="relative -bottom-2">
                  {item?.prize_type == 'manual' ? (
                    <>
                      <label className="text-brand w-full px-5  tracking-wide flex justify-center text-sm font-bold uppercase">
                        {`Manual Draw`}
                      </label>
                      <span className="text-white w-full px-5  tracking-wide flex justify-center text-lg font-bold ">
                        <label>{item?.win_position}</label>
                      </span>
                    </>
                  ) : (
                    <>
                      <label className="text-brand w-full px-5  tracking-wide flex justify-center text-sm font-bold uppercase">
                        {item?.prize_type}
                      </label>

                      <label className="text-white w-full  px-5  tracking-wide flex justify-center text-lg font-bold ">
                        {`Prize : ${item?.prize} ${item?.prize_type == 'Instant Points Wins' ? 'points' : ''}`}
                      </label>
                      <label className="text-white w-full px-5  tracking-wide flex justify-center text-xs font-thin uppercase  ">
                        {`( Ticket Number : ${item?.ticket} )`}
                      </label>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </MultiCarousel>
      </div>
    </div>
  );
};

export default RecentWinners;
