'use client';
import React from 'react';
import dynamic from 'next/dynamic';

const PostsList = dynamic(() => import('./PostsList'));

const PostContainer = ({ initialData }) => {
  const postsData = initialData?.edges;

  // console.log('postData=', initialData);

  const pageInfo = initialData?.pageInfo;

  // const handleLoadMore = (e) => {
  //   e.preventDefault();
  //   loadMore();
  // };

  return (
    <>
      <div className="w-full min-h-screen ">
        <PostsList posts={postsData || []} />
      </div>
    </>
  );
};

export default React.memo(PostContainer);
