import React, { Suspense } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import CardImage from './cardImage';

const StartingCardCountdownTimer = dynamic(
  () => import('@/app/components/ui/countdown/StartingCardCountDownTimer'),
  {
    ssr: false,
  },
);

const DrawCardCountdownTimer = dynamic(
  () => import('@/app/components/ui/countdown/DrawCardCountDownTimer'),
  {
    ssr: false,
  },
);

const GetParticipantProgress = ({ ticket = 0, participant = 0 }) => {
  // _lottery_participants_count "4799"
  // _max_tickets = "4799"

  const totalTicket = parseInt(ticket || 0);
  const TotalParticipants = parseInt(participant || 0);

  let progressPercent = 0;
  if (totalTicket > 0 && TotalParticipants > 0) {
    progressPercent = (TotalParticipants / totalTicket) * 100;
  }

  return (
    <div className="flex h-6 w-full items-center justify-between rounded-full bg-gray-200 p-2 dark:bg-gray-700">
      {progressPercent < 100 ? (
        <div
          className="h-4 rounded-full bg-green-600"
          style={{ width: `${progressPercent}%` }}
        />
      ) : (
        <div
          className="h-4 rounded-full bg-red-600"
          style={{ width: `${progressPercent}%` }}
        />
      )}

      <div className="absolute right-6 z-[2] flex h-8 items-center justify-center text-[10px] text-dark-500 dark:text-white xl:right-7 xl:text-sm">{`${TotalParticipants}/${totalTicket}`}</div>
    </div>
  );
};

const LotteryCountDown = ({ product }) => {
  const isLotteryStarted = product?.lotteryHasStarted == '1';

  const notLotteryClosed = product?.lotteryClosed !== '2';

  const lotteryToDate = product?.lotteryToDate || null;

  const lotteryFromDate = product?.lotteryFromDate || null;

  return (
    <>
      {/* <CountdownTimer targetDate={'2023-10-24 23:00'}/> */}
      {isLotteryStarted && (
        <DrawCardCountdownTimer targetDate={lotteryToDate} />
      )}

      {!isLotteryStarted && notLotteryClosed && (
        <StartingCardCountdownTimer targetDate={lotteryFromDate} />
      )}
    </>
  );
};

const LotteryCard = ({ p }) => {
  const isLotteryStarted = p?.node?.lotteryHasStarted || 0;

  const lotteryToDate = p?.node?.lotteryToDate || null;
  const totalTicket = parseInt(p?.node?.lotteryMaxTickets || 0);
  const TotalParticipants = parseInt(p?.node?.lotteryParticipantsCount || 0);

  const soldOut =
    isLotteryStarted == 1 && totalTicket == TotalParticipants ? true : false;

  let expired = true;

  if (lotteryToDate) {
    // const fromDate = new Date(lotteryFormDate);
    const toDate = new Date(lotteryToDate);
    const timeNow = new Date();

    if (toDate > timeNow) {
      expired = false;
    }
  }

  return (
    <div className="card space-y-4 rounded-lg bg-light-100 dark:bg-white/5 p-1 drop-shadow-lg backdrop-blur-sm  md:p-2  xl:p-3 ">
      <Link href={`/product/${p?.node?.slug}`} prefetch={false}>
        <div className="img-wraper relative overflow-hidden  h-[150px] md:h-[200px]  lg:h-[280px] 2xl:h-[320px] 3xl:h-[400px]">
          <Suspense>
            <CardImage p={p} />
          </Suspense>
        </div>
      </Link>
      <GetParticipantProgress
        ticket={p?.node?.lotteryMaxTickets || 0}
        participant={p?.node?.lotteryParticipantsCount || 0}
      />

      {!expired && (
        <div className="absolute top-[15%]">
          <LotteryCountDown product={p?.node || []} />
        </div>
      )}

      <div className="overflow-text h-[60px] max-h-[60px] min-h-[60px] text-center text-md font-normal uppercase text-dark-100 dark:text-brand md:text-lg  lg:text-[16px]   drop-shadow-xl">
        <Link href={`/product/${p?.node?.slug}`}>{p?.node?.title}</Link>
      </div>

      <div className="flex w-full text-[20px] dark:text-white text-dark-100 font-bold ">
        <div className="w-1/2 dark:text-red-400 ">{`${soldOut ? 'SOLD OUT' : ''}`}</div>

        <div className="w-1/2 flex justify-end">
          {`Price  £${p?.node?.lotteryPrice || 'FREE'}`}
        </div>
      </div>

      {expired ? (
        <div className="relative flex w-auto justify-center">
          <button className="btn-enter-giveaway my-5 text-[14px] font-bold uppercase  bg-gray-500">
            SEE WINNERS
          </button>
        </div>
      ) : (
        <>
          {soldOut ? (
            <>
              <div className="relative flex w-auto justify-center">
                <button className="btn-enter-giveaway my-5 text-[14px] font-bold uppercase bg-gray-500">
                  AWAITING RESULTS
                </button>
              </div>
            </>
          ) : (
            <>
              {isLotteryStarted ? (
                <div className="relative flex w-auto justify-center">
                  <Link
                    className="btn-enter-giveaway my-5 text-[14px] text-center font-bold uppercase bg-[#fb923c]"
                    href={`/product/${p?.node?.slug}`}
                  >
                    Enter Giveaway
                  </Link>
                </div>
              ) : (
                <div className="relative flex w-auto justify-center">
                  <button className="btn-enter-giveaway my-5 text-[14px] font-bold uppercase bg-[#009df9]">
                    Comming Soon
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LotteryCard;
