'use client';
import React from 'react';
import ReactPlayer from 'react-player/lazy';

// Lazy load the YouTube player
const VideoButn = () => {
  return (
    <span className=" flex  shrink-0 items-center justify-center border-4 border-white rounded-full px-2.5">
      <span className="h-20 w-20"></span>
    </span>
  );
};

const VideoPlayer = ({ url = '' }) => {
  return (
    <ReactPlayer
      url={url}
      className="react-player"
      width="100%"
      height="100%"
      controls
      pip={true}
      //   light={<VideoButn />}
    />
  );
};

export default VideoPlayer;
