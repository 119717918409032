'use client';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const MultiCarousel = (props) => {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      removeArrowOnDeviceType={['tablet', 'mobile']}
      ssr={false}
      // centerMode
      deviceType={props?.deviceType}
      // containerClass="container"
      draggable
      focusOnSelect={false}
      infinite
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      //   renderArrowsWhenDisabled={false}
      //   renderButtonGroupOutside={true}

      //   customButtonGroup={<ButtonGroup />}
      responsive={props?.responsive}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={true}
      renderDotsOutside={true}
      swipeable
      itemClass="carousel-item-padding-40-px"
    >
      {props?.children}
    </Carousel>
  );
};

export default MultiCarousel;
